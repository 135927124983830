export const types = [
  {
    text: 'Empty',
    value: 'null',
  }, {

    text: 'White List',
    value: 'white list',
  }, {
    text: 'Giveaway',
    value: 'giveaway',
  },

  //  {
  //   text: 'Referral',
  //   value: 'referral',
  // },
]

export const statuses = [{
  text: 'Active',
  value: 'active',
}, {
  text: 'Banned',
  value: 'banned',
}, {
  text: 'Locked',
  value: 'locked',
}]

export const actionStatuses = [{ text: 'Done', value: 'done' },
  { text: 'Stopped', value: 'stopped' },
  { text: 'In progress', value: 'in_progress' },
  { text: 'Pending', value: 'pending' }]

export const actionDetailsStatuses = [{ text: 'Success', value: 'success' },
  { text: 'Pending', value: 'pending' },
  { text: 'Error', value: 'error' },
  { text: 'In progress', value: 'in_progress' },
  { text: 'Banned', value: 'banned' },
  { text: 'Locked', value: 'locked' },
  { text: 'Proxy error', value: 'proxy_error' },
  { text: 'Skip', value: 'skip' },
  { text: 'Error captcha', value: 'error_captcha' },
  { text: 'Error skip Captcha', value: 'error_skip_captcha' },
]

export const StatusesColorMap = {
  success: { text: 'Success', value: 'success', color: 'success' },
  pending: { text: 'Pending', value: 'pending', color: 'blue-grey lighten-3' },
  error: { text: 'Error', value: 'error', color: 'error' },
  in_progress: { text: 'In progress', value: 'in_progress', color: 'indigo lighten-1' },
  banned: { text: 'Banned', value: 'banned', color: 'error' },
  locked: { text: 'Locked', value: 'locked', color: 'amber accent-3' },
  proxy_error: { text: 'Proxy error', value: 'proxy_error', color: 'error' },
  skip: { text: 'Skip', value: 'skip', color: 'light-blue darken-4' },
  error_captcha: { text: 'Error captcha', value: 'error_captcha', color: 'error' },
  error_skip_captcha: { text: 'Error skip Captcha', value: 'error_skip_captcha', color: 'error' },
  active: {
    color: 'success',
    text: 'Active',
    value: 'active',
  },
  not_active: {
    color: 'grey darken-3',
    text: 'Not active',
    value: 'not_active',
  },
  expired: {
    value: 'expired',
    text: 'Expired',
    color: 'amber accent-3',
  },
  rate_limit: {
    value: 'rate_limit',
    text: 'Rate limit',
    color: 'error',
  },
  'login required': {
    value: 'login required',
    text: 'Login required',
    color: 'lime darken-1',
  },
}

export const projectAccountStatuses = {
  innit: {
    text: 'Innit',
    color: 'indigo lighten-1',
  },
  not_verified: {
    text: 'Not verified',
    color: 'light-blue darken-4',
  },
  banned: {
    text: 'Banned',
    color: 'amber accent-3',
  },
  verified: {
    text: 'Verified',
    color: 'success',
  },
  low_verification: {
    color: 'grey darken-3',
    text: 'Low verification',
  },

}

export const verifications = [{
  text: 'Empty',
  value: 'null',
}, {
  text: 'Non verified',
  value: 'non verified',
}, {
  text: 'Mail verified',
  value: 'mail verified',
}, {
  text: 'Phone verified',
  value: 'phone verified',
}]

export const verificationsTypes = [{
  value: 'skip',
  text: 'Skip',
}, {
  value: 'emoji_add_reaction',
  text: 'Emoji add reaction',
}, {
  value: 'click_button',
  text: 'Click button',
}, {
  value: 'captcha_bot',
  text: 'Captcha bot',
}, {
  value: 'wick',
  text: 'Wick',
}, {
  value: 'sledgehammer',
  text: 'Sledgehammer',
}, {
  value: 'd_counter',
  text: 'DCounter',
}, {
  value: 'pandez_guard',
  text: 'Pandez Guard',
}, {
  text: 'Captcha bot (Slash Command)',
  value: 'captcha_slash_cmd_bot',
},
{
  value: 'pandez_guard_code',
  text: 'Pandez guard (Enter code)',

}, {
  value: 'server_supervisor',
  text: 'Server supervisor',
},
{
  value: 'captcha_image_bot',
  text: 'Captcha bot (Image)',
},
{
  value: 'vulcan',
  text: 'Vulcan Authentication',
},
{
  value: 'supervisor_calculator',
  text: 'Server Supervisor (Dial Pad)',
}]

export const statusColors = {
  pending: 'blue-grey',
  in_progress: 'blue',
  success: 'light-green',
  skip: 'deep-purple',
  banned: 'deep-orange',
  locked: 'amber',
  error_captcha: 'teal',
  error_skip_captcha: 'pink darken-4',
  proxy_error: 'orange darken-4',
  error: 'red',
}

<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="proxy">Proxy</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <SelectProxy
          v-model="proxy"
          :rules="[isTrue]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="phone">Phone</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="phone"
          v-model="phone"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter phone"
          :disabled="loading"
          :rules="[required,integerValidator]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="apiHash">API Hash</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="apiHash"
          v-model="apiHash"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter API hash"
          :disabled="loading"
          :rules="[required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="apiId">API Id</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="apiId"
          v-model="apiId"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter API Id"
          :disabled="loading"
          :rules="[required,integerValidator]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        offset-md="3"
        cols="12"
      >
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Create
        </v-btn>
        <v-btn
          type="reset"
          class="mx-2"
          :disabled="loading"
          outlined
          @click="reset"
        >
          Reset
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiAlertCircleOutline, mdiCalendar, mdiCheck } from '@mdi/js'

import { required, integerValidator, isTrue } from '@core/utils/validation'
import SelectProxy from '@/components/proxy/SelectProxy.vue'

export default {
  components: { SelectProxy },
  props: {
    loading: Boolean,
  },
  setup(props, { emit }) {
    const form = ref(null)
    const validate = () => form.value.validate()
    const reset = () => {
      form.value.reset()
    }
    const apiHash = ref('')
    const apiId = ref('')
    const phone = ref('')
    const proxy = ref()

    const submit = async () => {
      if (validate()) {
        const data = {
          api_hash: apiHash.value,
          api_id: apiId.value,
          phone: phone.value,
          proxy: proxy.value,
        }
        emit('submit', data)
      }
    }

    return {
      form,
      isTrue,
      apiHash,
      apiId,
      phone,
      proxy,
      validate,
      reset,
      required,
      integerValidator,
      submit,
      icons: {
        mdiCheck,
        mdiAlertCircleOutline,
        mdiCalendar,
      },
    }
  },
}
</script>

<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-show="selectedAccounts.length"
        class="mr-6"
        icon
        color="error"
        @click="tryDeleteManyAccounts(selectedAccounts)"
      >
        <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
      </v-btn>
      <TableSearch
        v-model="tableOptions.search"

        hide-filter
      />
      <v-spacer />
      <AddNewAccount
        @submitted="tryFetchAccounts"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selectedAccounts"
        hide-default-footer
        :mobile-breakpoint="0"
        item-key="id"
        loading-text="Loading accounts ..."
        :server-items-length="total"
        :loading="loading"
        :items="accounts"
        :headers="accountHeaders"
        :options.sync="tableOptions"
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"
      >
        <template v-slot:header.status="{ header }">
          <span>{{ header.text }}</span>
        </template>
        <!-- SELECT ROW-->
        <template v-slot:header.data-table-select="{ props, on }">
          <CustomSelectRows
            :value="props"
            :page="tableOptions.page"
            :total="total"
            v-on="on"
          />
        </template>
        <template v-slot:item.value="{ item }">
          <v-skeleton-loader
            :loading="item.loading"
            type="table-cell"
          >
            {{ item.value }}
          </v-skeleton-loader>
        </template>
        <template v-slot:item.verified="{ item }">
          <v-chip
            v-if="item.verified"
            color="success"
          >
            Verified
          </v-chip>
        </template>
        <template v-slot:item.proxy="{ item, value }">
          <v-tooltip
            v-if="value"
            right
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <span

                v-bind="attrs"
                v-on="on"
              >
                {{ value.name ||value.value }}
              </span>
            </template>
            <span v-show="value.name">{{ value.value }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="StatusesColorMap[item.status] && StatusesColorMap[item.status].color"
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <TableActions
            :loading="item.loading"
            @edit="openAccountEditModal(item)"
            @delete="tryDeleteAccount(item.id)"
          />
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          />
        </template>
      </v-data-table>
    </v-card-text>

    <AccountEditModal
      ref="editAccountModal"
      @update="tryUpdateAccount"
    />

    <ConfirmDialog
      ref="deleteAccountConfirm"
      max-width="400"
    >
      <template v-slot="{agree, cancel}">
        <v-card>
          <v-card-title>Delete account</v-card-title>
          <v-card-text>
            Do you really want to delete account
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="agree"
            >
              <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
              Delete
            </v-btn>
            <v-btn
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-card>
</template>

<script>
import {
  mdiDeleteForever,
  mdiDotsVertical,
  mdiPencil,
  mdiMagnify,
} from '@mdi/js'
import pick from 'lodash/pick'
import { mapActions, mapMutations, mapState } from 'vuex'
import { StatusesColorMap } from '@core/constants'
import store from '@/store'
import telegramStoreModule, { TELEGRAM_MODULE_NAME } from '@/store/modules/telegramStoreModule'
import CustomSelectRows from '@/components/CustomSelectRows.vue'
import AddNewAccount from '@/components/telegram/account/AddNew.vue'
import AccountEditModal from '@/components/telegram/account/EditModal.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import TableActions from '@/components/TableActions.vue'
import TableFooter from '@/components/TableFooter.vue'
import TableSearch from '@/components/TableSearch.vue'

export default {
  components: {
    CustomSelectRows,
    AddNewAccount,
    AccountEditModal,
    ConfirmDialog,
    TableActions,
    TableFooter,
    TableSearch,
  },
  data() {
    return {
      loading: true,
      total: 50,
      StatusesColorMap,
      tableOptions: {
        search: this.$route.query.search || '',
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
      },
      statusColorMap: {
        'login required': {
          color: 'indigo lighten-1',
        },
        active: {
          color: 'success',
        },
      },
      search: '',
      selectedAccounts: [],
      pages: [50, 100, 200],
      icons: {
        mdiDotsVertical,
        mdiDeleteForever,
        mdiPencil,
        mdiMagnify,
      },
      accountHeaders: [
        {
          text: 'Phone',
          value: 'phone',
          sortable: true,
          filterable: true,
          align: 'start',
        },
        {
          text: 'API Id',
          value: 'api_id',
          sortable: true,
          filterable: true,
          align: 'start',
        },
        {
          text: 'Proxy',
          value: 'proxy',
          sortable: true,
          filterable: true,
          align: 'start',
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true,
          filterable: true,
          align: 'start',
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      accounts(state) {
        return state[TELEGRAM_MODULE_NAME]?.account.accounts
      },
    }),
    mappedOptions() {
      return this.$route.query
    },
  },

  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search', 'status'])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.tryFetchAccounts(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },

  mounted() {
    // Register module
    if (!store.hasModule(TELEGRAM_MODULE_NAME)) {
      store.registerModule(TELEGRAM_MODULE_NAME, telegramStoreModule)
    }
  },

  methods: {
    ...mapActions({
      fetchAccounts(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/fetchAccounts`, payload)
      },
      updateAccount(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/updateAccount`, payload)
      },
      removeAccount(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/removeAccount`, payload)
      },
    }),

    ...mapMutations({
      commitSetAccounts(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/SET_ACCOUNTS `, payload)
      },
      commitEditAccount(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/EDIT_ACCOUNT`, payload)
      },
      commitDeleteAccount(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/REMOVE_ACCOUNT`, payload)
      },
    }),

    async tryFetchAccounts(params = this.mappedOptions) {
      this.loading = true
      const { data } = await this.fetchAccounts(params)
      this.total = data.total
      this.loading = false
    },

    async tryDeleteAccount(id) {
      this.$refs.deleteAccountConfirm.open().then(() => {
        this.commitEditAccount({ id, loading: true })
        this.removeAccount({ id }).then(() => {
          this.commitDeleteAccount({ id })
          this.$message.success('Account deleted successfully')
        }).catch(err => this.$message.error(err))
      })
    },

    async tryUpdateAccount(value) {
      this.commitEditAccount({ ...value, loading: true })
      const { data } = await this.updateAccount({ account: value })
      this.commitEditAccount(data)
    },

    openAccountEditModal(account) {
      this.$refs.editAccountModal.open(account)
    },
  },
}
</script>

<template>
  <div class="text-center">
    <v-bottom-sheet
      v-model="sheet"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          :attrs="attrs"
          :elevation="12"
          fab
          v-on="on"
        >
          <v-icon>{{ mdiPlus }}</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Create Account</v-card-title>
        <v-card-text>
          <AddAccountForm
            v-if="sheet"
            :loading="loading"
            @submit="submit"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'

import store from '@/store'
import { TELEGRAM_MODULE_NAME } from '@/store/modules/telegramStoreModule'

import AddAccountForm from './AddForm.vue'

export default {
  components: { AddAccountForm },
  data: () => ({
    mdiPlus,
    sheet: false,
    loading: false,
  }),
  methods: {
    async submit(account) {
      try {
        this.loading = true
        await store.dispatch(`${TELEGRAM_MODULE_NAME}/addAccount`, { account })
        this.sheet = false
        this.loading = false
        this.$emit('submitted')
        this.$message.success('Account added successfully')
      } catch (err) {
        this.sheet = false
        this.loading = false
        this.$message.error(err)
      }
    },
  },
}
</script>

<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="proxy">Proxy</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <SelectProxy
          v-model="proxy"
          :rules="[isTrue]"
          :account-proxies="accountProxy"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="phone">Phone</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="phone"
          v-model="phone"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter phone"
          :rules="[required,integerValidator]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="apiHash">API Hash</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="apiHash"
          v-model="apiHash"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter API hash"
          :rules="[required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="apiId">API Id</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="apiId"
          v-model="apiId"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter API Id"
          :rules="[required,integerValidator]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="status == 'login required'">
      <v-col
        offset-md="3"
        cols="9"
      >
        <VerifyAccount
          v-model="verified"
          :account-id="accountId"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required, integerValidator, isTrue } from '@core/utils/validation'
import { mdiAlertCircleOutline, mdiCalendar, mdiCheck } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import VerifyAccount from './VerifyAccount.vue'
import SelectProxy from '@/components/proxy/SelectProxy.vue'

export default {
  components: { VerifyAccount, SelectProxy },
  props: {
    account: {
      type: Object,
      default: () => { },
    },
  },
  setup({ account }) {
    const form = ref(null)

    const validate = () => form.value.validate()
    const reset = () => form.value.reset()
    const apiHash = ref(account.api_hash)
    const apiId = ref(account.api_id)
    const phone = ref(account.phone)
    const proxy = ref(account.proxy?.id || account.proxy)
    const verified = ref(account.verified)
    const status = ref(account.status)
    const accountId = account.id

    const accountProxy = computed(
      () => account.proxy && {
        text: account.proxy.name,
        value: account.proxy.id,
        description: account.proxy.value,
      },
    )

    const submit = async () => ({
      id: account.id,
      api_hash: apiHash.value,
      api_id: apiId.value,
      phone: phone.value,
      proxy: proxy.value,
    })

    return {
      form,
      apiHash,
      apiId,
      phone,
      proxy,
      status,
      accountId,
      isTrue,
      verified,
      validate,
      reset,
      submit,
      required,
      integerValidator,
      accountProxy,
      icons: {
        mdiCheck,
        mdiAlertCircleOutline,
        mdiCalendar,
      },
    }
  },
}
</script>

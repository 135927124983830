<template>
  <div>
    <template
      v-if="stage === 2 || value"
    >
      <v-icon color="success">
        {{ icons.mdiCheckCircle }}
      </v-icon>
      <span class="text-success ml-2">Verified</span>
    </template>
    <v-btn
      v-else-if="stage === 0"
      color="primary"
      :loading="loading"
      @click="requestCode"
    >
      Verify Number
    </v-btn>
    <template v-else-if="stage === 1">
      <v-text-field
        v-model="code"
        placeholder="Enter code"
        :disabled="loading"
      />
      <v-btn
        color="primary"
        :loading="loading"
        @click="sendCode"
      >
        Send Code
      </v-btn>
    </template>
  </div>
</template>

<script>
import { mdiCheckCircle } from '@mdi/js'
import TelegramApi from '@core/api/telegram'

export default {
  props: {
    value: Boolean,
    accountId: { type: Number, default: null },
  },
  data: () => ({
    code: '',
    loading: false,
    stage: 0,
    icons: { mdiCheckCircle },
  }),
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async requestCode() {
      TelegramApi.Accounts.login({
        id: this.accountId,
      }).catch(err => this.$message.error(err))
      this.stage = 1
    },
    async sendCode() {
      try {
        this.loading = true

        await TelegramApi.Accounts.verifiedCode({
          id: this.accountId,
          code: this.code,
        })
        this.stage = 2
        this.localValue = true
        this.$message.success('Number successfully verified')
      } catch (error) {
        this.$message.error(error)
      }

      this.loading = false
    },
  },
}
</script>

<style>

</style>

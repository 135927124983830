var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedAccounts.length),expression:"selectedAccounts.length"}],staticClass:"mr-6",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.tryDeleteManyAccounts(_vm.selectedAccounts)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))])],1),_c('TableSearch',{attrs:{"hide-filter":""},model:{value:(_vm.tableOptions.search),callback:function ($$v) {_vm.$set(_vm.tableOptions, "search", $$v)},expression:"tableOptions.search"}}),_c('v-spacer'),_c('AddNewAccount',{on:{"submitted":_vm.tryFetchAccounts}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","mobile-breakpoint":0,"item-key":"id","loading-text":"Loading accounts ...","server-items-length":_vm.total,"loading":_vm.loading,"items":_vm.accounts,"headers":_vm.accountHeaders,"options":_vm.tableOptions,"footer-props":{
        'items-per-page-options': _vm.pages,
        'show-first-last-page': true,
        'show-current-page': true,
      }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header.status",fn:function(ref){
      var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.text))])]}},{key:"header.data-table-select",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('CustomSelectRows',_vm._g({attrs:{"value":props,"page":_vm.tableOptions.page,"total":_vm.total}},on))]}},{key:"item.value",fn:function(ref){
      var item = ref.item;
return [_c('v-skeleton-loader',{attrs:{"loading":item.loading,"type":"table-cell"}},[_vm._v(" "+_vm._s(item.value)+" ")])]}},{key:"item.verified",fn:function(ref){
      var item = ref.item;
return [(item.verified)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" Verified ")]):_vm._e()]}},{key:"item.proxy",fn:function(ref){
      var item = ref.item;
      var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(value.name ||value.value)+" ")])]}}],null,true)},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(value.name),expression:"value.name"}]},[_vm._v(_vm._s(value.value))])]):_vm._e()]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.StatusesColorMap[item.status] && _vm.StatusesColorMap[item.status].color}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('TableActions',{attrs:{"loading":item.loading},on:{"edit":function($event){return _vm.openAccountEditModal(item)},"delete":function($event){return _vm.tryDeleteAccount(item.id)}}})]}},{key:"footer",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on))]}}]),model:{value:(_vm.selectedAccounts),callback:function ($$v) {_vm.selectedAccounts=$$v},expression:"selectedAccounts"}})],1),_c('AccountEditModal',{ref:"editAccountModal",on:{"update":_vm.tryUpdateAccount}}),_c('ConfirmDialog',{ref:"deleteAccountConfirm",attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var agree = ref.agree;
      var cancel = ref.cancel;
return [_c('v-card',[_c('v-card-title',[_vm._v("Delete account")]),_c('v-card-text',[_vm._v(" Do you really want to delete account ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":agree}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))]),_vm._v(" Delete ")],1),_c('v-btn',{on:{"click":cancel}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }